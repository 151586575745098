import React from 'react'
import Layout from "../components/App/Layout"
import Footer from "../components/App/Footer"
import ContactFormMentoree from '../components/Contact/ContactFormMentoree'
import PageBanner from "../components/Common/PageBanner";

const Services = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <PageBanner
                pageTitle="MENTOREE"
                homePageText="Écosystème"
                homePageUrl="/ecosysteme"
                activePageText="Acteur innovant de l’emploi, de la formation et de l'accompagnement des DRH et candidats"
            />
            <ContactFormMentoree />
            <Footer />
        </Layout>
    );
}

export default Services;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;